<template>
  <div
    class="lm--cover-page lm--vertical-center"
    :style="`background-image: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.1))})`"
  >
    <Slide selectorId="slide-header">
      <template v-slot:slide-items>

        <!-- SLIDE 2025 -->
        <div class="swiper-slide default-slide lm--bg-image">
          <v-container>
            <div class="lm--content-slide">
              <div class="lm--left-content lm--vertical-center">
              </div>
            </div>
          </v-container>
        </div>

<!--
        <div class="swiper-slide first-slide lm--bg-image">
          <v-container>
            <div class="lm--content-slide">
              <div class="lm--left-content lm--vertical-center">
                <div>
                  <h2 class="lm--title-cover">
                    {{ title1 ? title1 : "Compra entradas de tus" }}
                  </h2>
                  <h2 class="lm--title-cover lm--special-color">
                    {{ title2 ? title2 : "centros de esparcimiento" }}
                  </h2>
                  <h2 class="lm--title-cover">{{ title3 ? title3 : "Favoritos" }}</h2>
                </div>
              </div>
              <div class="lm--vertical-center lm--right-content">
                <img draggable="false" :src="logo" alt="Logo" />
              </div>
            </div>
          </v-container>
        </div>
-->

        <div class="swiper-slide visita-lima-slide lm--bg-image">
          <v-container fluid class="lm--container-slide">
            <div class="lm--content-slide-2">
              <div class="lm--left-content">
                <img draggable="false" :src="visitalimaIMG" alt="Catedral de Lima" title="Catedral de Lima" />
              </div>
              <div class="lm--right-content">
                <h1>{{ visitaLimaTitle }}</h1>
                <p>
                  {{ visitaLimaDescription }}
                </p>
                <a target="_blank" :href="visitaLimaURL">
                  <span>{{ visitaLimaURLPhrase }}</span>
                  <i class="fas fa-external-link-alt ml-2"></i>
                </a>
              </div>
            </div>
            <div class="bg-image-2 lm--bg-image"></div>
          </v-container>
        </div>
      </template>
    </Slide>

    <!-- Search -->
   <!--  <div class="lm--container-searcher container">
      <div class="lm--searcher boder-circle">
        <input
          id="search-input"
          autofocus
          type="text"
          :placeholder="
            searchHolder ? searchHolder : 'Busca entre los centros de esparcimiento'
          "
          autocomplete="off"
        />
        <button disabled type="button">
          <i class="fas fa-search"></i>
        </button>
        <div class="lm--datalist">
          <button
            type="button"
            v-for="(item, idx) in results"
            :key="idx + 100"
            :to="`/${item.slug}`"
            class="lm--datalist-item"
            @click="openParkInfo(item)"
          >
            <span>{{ item.DESCRIPCION }}</span>
          </button>
        </div>
      </div>
    </div> -->
    <!-- End search -->

  </div>
</template>

<script>
import Slide from "@/components/slides/Slide";
export default {
  components: {
    Slide,
  },
  props: {
    bgImage: String,
    logo: String,
    title1: String,
    title2: String,
    title3: String,
    searchHolder: String,
    // visita lima
    visitalimaIMG: String,
    visitaLimaTitle: String,
    visitaLimaDescription: String,
    visitaLimaURL: String,
    visitaLimaURLPhrase: String,
  },
  /* data() {
    return {
      results: [],
    };
  },
  mounted() {
    const searcher = document.getElementById("search-input");
    searcher.addEventListener("focus", this.onFocusEv);
    searcher.addEventListener("blur", this.removeActive);
    searcher.addEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    onFocusEv() {
      document.querySelector(".lm--searcher").classList.add("active");
    },
    removeActive() {
      document.querySelector(".lm--searcher").classList.remove("active");
    },
    activeResultsList() {
      document.querySelector(".lm--searcher").classList.add("results");
    },
    removeResultsList() {
      document.querySelector(".lm--searcher").classList.remove("results");
      this.results = [];
    },
    handleKeyUp(ev) {
      if (ev.target.value && ev.target.value.length > 0) {
        //busqueda
        this.searchResults(ev.target.value);
        if (this.results.length > 0) {
          this.onFocusEv();
          this.activeResultsList();
        } else {
          this.removeResultsList();
        }
      } else {
        this.removeResultsList();
      }
    },
    searchResults(keyword) {
      //find
      const list = [...this.$store.state.parkList];
      this.results = list.filter((it) => {
        const arr = it.DESCRIPCION.split(" "); //arr keywords on items
        let obj = null;
        arr.forEach((name) => {
          const arrKeyUp = keyword.split(" ");
          arrKeyUp.forEach((key) => {
            if (this.removeSpecialC(name).indexOf(this.removeSpecialC(key)) > -1) {
              obj = it;
            }
          });
        });
        if (obj) return it;
      });
    },
    removeSpecialC(str) {
      str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      str = str.toLowerCase();
      str = str.trim();
      return str;
    },
    openParkInfo(item){
      this.$store.dispatch('changeCurrentPark', item);
      this.$store.dispatch('openModal');
    }
  }, */
};
</script>

<style lang="scss" scoped>
.lm--cover-page {
  width: 100%;
  height: calc(90vh);
  min-height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 600px;
  font-family: $primary-font;
  position: relative;
  @media screen and (max-width: 1000px) {
    height: calc(70vh - 71px);
  }
  @media screen and (max-width: 599px) {
    height: calc(100vh - 71px);
  }
  @media screen and (max-width: 400px) {
    height: calc(100vh - 65px);
  }
  .lm--left-content,
  .lm--right-content {
    width: 100%;
    /* display: block; */
    max-width: 470px;
    @media screen and (min-width: 1650px) {
      max-width: 540px;
    }
    @media screen and (min-width: 1750px) {
      max-width: 540px;
    }
    @media screen and (min-width: 1850px) {
      max-width: 540px;
    }
    @media screen and (min-width: 1900px) {
      max-width: 570px;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
  }

  .lm--content-slide {
    display: flex;
    justify-content: center !important;
    flex-flow: row nowrap;
    padding-bottom: 20px;
      .lm--right-content {
        display: flex;
        justify-content: center;
      }
    @media screen and (max-width: 599px) {
      flex-flow: column nowrap;
      align-items: center;
      
      padding-bottom: 80px;
    }
  }

  .lm--left-content {
    display: flex;
    align-items: center;
    @media screen and (max-width: 599px) {
      justify-content: center;
      padding-top: 30px;
    }
    .lm--title-cover {
      font-size: 2.4rem;
      font-weight: 600;
      color: #fff;
      @media screen and (min-width: 1450px) {
        font-weight: 700;
      }
      @media screen and (min-width: 1650px) {
        font-size: 2.8rem;
      }
      @media screen and (min-width: 1900px) {
        font-size: 2.9rem;
      }
      @media screen and (max-width: 850px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 650px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 599px) {
        font-size: 1.8rem;
        text-align: center;
      }
      @media screen and (max-width: 450px) {
        font-size: 1.5rem;
        text-align: center;
      }
    }
    .lm--title-cover.lm--special-color {
      color: $color-secondary;
      font-weight: 700;
    }
  }

  .lm--right-content {
    /* display: flex; */
    img {
      max-width: 90%;
      max-height: 75%;
      margin-left: auto;
      @media screen and (min-width: 1500px) {
        max-width: 90%;
        max-height: 80%;
      }
      @media screen and (max-width: 599px) {
        margin: 1em auto;
        max-height: 150px;
      }
      @media screen and (max-width: 400px) {
        margin: 1em auto;
        max-height: 115px;
      }
    }
  }
}
.default-slide {
  background: url("../assets/img/slider-web.png");
}

.first-slide {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.28),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.1)
    ),
    url("../assets/img/background.jpg");
}

// visita lima slide
.swiper-slide.visita-lima-slide {
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.28),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 1)
    );
    z-index: 10;
  }
  .lm--container-slide {
    overflow: hidden;
    padding-left: 0 !important;
  }
  .bg-image-2 {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 10%,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0, 0.5) 80%,
        rgba(0, 0, 0, 0.8)
      ),
      url(../assets/img/bg-visitalima.jpg);
    -webkit-mask-image: linear-gradient(90deg, transparent 0, #242829 30%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* margin-left: 25%; */
    /* background-image: ; */
  }
  .lm--content-slide-2 {
    width: 100%;
    height: 100%;
    padding: 40px 30px 40px 30px;
    display: flex;
    align-items: center;
    max-height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 10;
    @media screen and (max-width: 420px) {
      padding: 40px 15px 40px 15px;
    }
    .lm--left-content {
      max-width: 350px;
      width: 30%;
      min-width: 280px;
      height: 50vh;
      display: flex;
      align-items: center;
      margin-right: 15px;
      //margin-bottom: 40px;
      @media screen and (max-width: 650px){
          display: none;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 500px;
        object-fit: cover;
        border-radius: 3px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.18);
      }
    }
    .lm--right-content {
      padding: 1em;
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      overflow: hidden;
      //margin-bottom: 40px;
      color: #fff;
      margin-top: auto;
      font-family: $primary-font;
      /* background: linear-gradient(180deg,rgba(0,0,0,.35) 10%,rgba(0,0,0,.08) 60%,rgba(0,0,0,.38) 80%,rgba(0,0,0,.55));; */
      h1 {
        color: #fff;
        font-size: 2.55em;
        font-weight: 600;
        font-weight: bold;
        text-decoration: none;
      }
      p {
        -webkit-line-clamp: 4;
        color: hsla(0, 0%, 100%, 0.85);
        display: block;
        display: -webkit-box;
        font-size: 0.89em;
        line-height: 1.5em;
        overflow: hidden;
      }
      a {
        align-items: center;
        background: $color-tertiary;
        border-radius: 2px;
        color: #fff;
        cursor: pointer;
        display: inline-flex;
        font-size: 0.85em;
        font-weight: 600;
        letter-spacing: 1px;
        margin: 0;
        padding: 7px 13px;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}

.lm--bg-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

//animations
.swiper-slide.swiper-slide-active {
  .lm--left-content {
    /* animation: bounceUp ease-in-out 2s; */
    h2:nth-child(1) {
      animation: bounceUp ease-in-out 1s;
    }
    h2:nth-child(2) {
      animation: bounceUp ease-in-out 1.45s;
    }
    h2:nth-child(3) {
      animation: bounceUp ease-in-out 1.75s;
    }
    img {
      animation: bounceRight ease-in-out 1s;
    }
  }
  .lm--right-content {
    img {
      animation: bounceLeft ease-in-out 1.2s;
    }
    h1 {
      animation: bounceUp ease-in-out 1s;
    }
    p {
      animation: bounceUp ease-in-out 1.25s;
    }
    a {
      animation: bounceUp ease-in-out 1.5s;
    }
  }
}

@keyframes scaleAn {
  from {
    transform: scale(.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes opacityAn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceUp {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounceDown {
  from {
    transform: translateY(-200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounceLeft {
  from {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes bounceRight {
  from {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}



//Searcher
/* .lm--container-searcher {
  position: absolute;
  width: 100%;
  left: 50%;
  height: $searcherHeight;
  top: calc(100% - 45px);
  background: #f4f4f4;
  border-radius: 5px;
  box-shadow: -1px 4px 74px -16px rgba(0, 0, 0, 0.3);
  display: block;
  transform: translate(-50%, 0);
  padding: 0 12px !important;
  z-index: 10;
  @media screen and (max-width: 599px) {
    border-radius: 5px 5px 0 0;
    top: calc(100% - 90px);
    display: flex;
    align-items: center;
  }
}

.lm--searcher {
  padding: 0.55em 1em;
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin: 1em 0;
  transition: all ease-in-out 0.2s;
  position: relative;
  max-width: 500px;
  margin: 1em auto;
  @media screen and (min-width: 1650px) {
    max-width: 550px;
  }
  @media screen and (min-width: 1900px) {
    max-width: 800px;
  }
  @media screen and (max-width: 850px) {
    padding: 0.5em 1em;
  }
  @media screen and (max-width: 650px) {
    padding: 0.5em 0.8em;
  }
  @media screen and (max-width: 599px) {
    margin: 1em auto;
    width: 80%;
    max-width: 80%;
  }
  @media screen and (max-width: 450px) {
    max-width: 88%;
    width: 88%;
    padding: 0.3em 1em;
  }
  @media screen and (max-width: 400px) {
    max-width: 95%;
    width: 95%;
    padding: 0.3em 1em;
  }
  @media screen and (max-width: 353px) {
    max-width: 100%;
    padding: 0.3em 1em;
  }
  &.boder-circle {
    border-radius: 20px;
  }
  input {
    width: 100%;
    padding: 0.6em 0;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    @media screen and (max-width: 400px) {
      font-size: 13px;
    }
    @media screen and (max-width: 353px) {
      font-size: 12px;
    }
  }
  button {
    background: transparent;
    color: $color-tertiary;
    outline: none;
  }
}
.lm--searcher.active {
  box-shadow: 1px 1px 1px 3px rgba(26, 74, 132, 0.3);
}

.lm--datalist {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  height: auto;
  background: #fff;
  padding: 0.5em 0;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.2s;
  box-shadow: 1px 1px 1px 3px rgba(26, 74, 132, 0.3);
  display: flex;
  flex-flow: column nowrap;
  .lm--datalist-item {
    padding: 0.4em 1.5em;
    text-align: left;
    outline: none;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-weight: normal;
    text-decoration: none;
    transition: all ease-in-out 0.15s;
    &:hover {
      background: $color-tertiary;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
.lm--searcher.results.active {
  border-radius: 20px 20px 0 0;
  .lm--datalist {
    visibility: visible;
    opacity: 1;
  }
}

.lm--vertical-center {
  display: flex;
  align-items: center;
}
.lm--vertical-center:first-of-type {
  @media screen and (max-width: 599px) {
    order: 3;
  }
} */
</style>
