<template>
  <div class="lm--header">
    <v-container class="lm--header-container">
      <div class="lm--left-header">
        <!-- :href="muniLink" target="_blank" -->
        <router-link v-if="!disabledLogoLink" to="/">
         <div class="d-flex">
          <Logo
            class="navbar-brand "
            :source="logoAppMuni"
            description="Logo Municipalidad de Lima"
          />
          <span class="navbar-brand mx-2" style="width:2px;background-color: white;"></span>
          <Logo
            class="navbar-brand "
            :source="logoMuni"
            description="Logo Municipalidad de Lima"
          />
         </div>
        </router-link>
        <Logo
          v-else
          class="navbar-brand"
          :source="logoMuni"
          description="Logo Municipalidad de Lima"
        />
      </div>

      <div class="lm--right-header">
        <slot name="content-right"></slot>
        <div v-if="!hideSocials" class="lm--social-links nav-link">
          <SocialLink
            v-for="(item, idx) in socialItems"
            :key="idx"
            :href="item.redirectTo"
            :icon="item.icon"
            :title="item.title"
          />
        </div>
        <Lang v-if="!hideLang" :lang="lang" class="nav-link" />
        <AuthMenu v-if="!hideAuthMenu" class="nav-link"></AuthMenu>
      </div>
    </v-container>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import SocialLink from "@/components/SocialLink";
import Lang from "@/components/Lang";
import AuthMenu from "@/components/AuthMenu";
import { mapState } from "vuex";
export default {
  props: {
    isPrincipalDomain: Boolean,
    hideAuthMenu: Boolean,
    hideLang: Boolean,
    hideSocials: Boolean,
    disabledLogoLink: Boolean,
  },
  components: {
    Logo,
    SocialLink,
    Lang,
    AuthMenu,
  },
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      logoMuni: (state) => state.logoMuni,
      logoAppMuni: (state) => state.logoAppMuni,
      logoApp: (state) => state.logoApp,
      muniLink: (state) => state.muniLink,
      socialItems: (state) => state.socialItems,
    }),
  },
};
</script>

<style lang="scss" scoped>
.lm--header {
  background: $color-primary;
  border-bottom: 4px solid $color-secondary;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  z-index: 100;
  .lm--header-container {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
      padding: 0 12px !important;
    }
    .lm--left-header {
      display: flex;
      align-items: center;
    }
    .lm--right-header {
      display: flex;
      align-items: center;
      .lm--social-links {
        margin-left: 10px;
        display: flex;
        @media screen and (max-width: 450px) {
          display: none;
        }
      }
    }
  }
}
</style>
